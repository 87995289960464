// Functions first
@import '../../../node_modules/bootstrap/scss/functions';
// Variable overrides second
$primary: #ed6745;
$success: #4dba9d;
$danger: #ed6745;
$dark: #828282;
$accordion-button-active-color: inherit;
$accordion-button-active-bg: #f8f9fa;
$min-contrast-ratio: 3;
$enable-smooth-scroll: false;
$pageHeight: calc(100vh - 70px);
// Required Bootstrap imports
@import '../../../node_modules/bootstrap/scss/variables';
// @import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/root';
// Optional components
@import '../../../node_modules/bootstrap/scss/utilities';
// Personalisation
$utilities: map-merge(
	$utilities,
	(
		'width':
			map-merge(
				map-get($utilities, 'width'),
				(
					responsive: true,
				)
			),
	)
);
@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/navbar';
@import '../../../node_modules/bootstrap/scss/transitions';
@import '../../../node_modules/bootstrap/scss/nav';
@import '../../../node_modules/bootstrap/scss/buttons';
@import '../../../node_modules/bootstrap/scss/containers';
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/type';
@import '../../../node_modules/bootstrap/scss/forms/form-control';
@import '../../../node_modules/bootstrap/scss/forms/input-group';
@import '../../../node_modules/bootstrap/scss/forms/form-select';
@import '../../../node_modules/bootstrap/scss/forms/validation';
@import '../../../node_modules/bootstrap/scss/forms//form-check';
@import '../../../node_modules/bootstrap/scss/card';
@import '../../../node_modules/bootstrap/scss/dropdown';
@import '../../../node_modules/bootstrap/scss/badge';
@import '../../../node_modules/bootstrap/scss/breadcrumb';
@import '../../../node_modules/bootstrap/scss/button-group';
@import '../../../node_modules/bootstrap/scss/pagination';
@import '../../../node_modules/bootstrap/scss/alert';
@import '../../../node_modules/bootstrap/scss/accordion';
@import '../../../node_modules/bootstrap/scss/images';
@import '../../../node_modules/bootstrap/scss/tables';
@import '../../../node_modules/bootstrap/scss/progress';
@import '../../../node_modules/bootstrap/scss/close';
@import '../../../node_modules/bootstrap/scss/spinners';
@import '../../../node_modules/bootstrap/scss/helpers/visually-hidden';
@import '../../../node_modules/bootstrap/scss/helpers/position';
@import '../../../node_modules/bootstrap/scss/utilities/api';

@import 'theme.scss';
