$btn-border-radius: $border-radius;
$input-border-radius: $border-radius;

$card-border-radius: $border-radius;
$card-inner-border-radius: $border-radius;

$navbar-light-color: $dark;

$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;

:checked {
	border-color: #80bdff !important;
	background-color: #80bdff !important;
}

.button,
.page-link,
.card,
picture,
img,
.not-selectable {
	user-select: none;
}

button:focus {
	box-shadow: none !important;
}

select:focus,
input:focus {
	border-color: #80bdff !important;
	box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
}

.breadcrumb {
	background-color: #e9ecef;
	border-radius: 0.25rem;
	padding: 0.75rem 1rem !important;
}

@media (min-width: 1400px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl,
	.container-xxl {
		max-width: 1140px !important;
	}
}

.badge {
	padding: 0.25em 0.4em !important;
}

.card-body {
	padding: 1.25rem !important;
}

body {
	color: #333 !important;
}

.d-block + .d-block {
	margin-top: 0.5rem;
}

button {
	cursor: pointer;
}

.btn-dark,
.btn-danger,
.btn-primary {
	color: #ffffff !important;
}

.btn-success,
.btn-secondary,
.btn-outline-success:hover,
.btn-outline-primary:hover,
.badge-success {
	color: #ffffff !important;
}

footer {
	z-index: 2 !important;
	position: relative !important;
}

.with-shadow {
	box-shadow: 1px 2px 8px $dark;
}

.col-min-height {
	min-height: 450px;
}

.block {
	@extend .p-4 !optional;
	@extend .border !optional;
	border-radius: $border-radius;
}

ul.navbar-nav li.nav-item:hover {
	cursor: pointer;
	a.nav-link {
		color: none !important;
		text-decoration: none;
	}
}

.featurette-divider {
	margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

.title-spacing {
	margin-bottom: 5rem;
}

.one-line-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #333;
}

.card {
	.card-img-top {
		@extend .d-flex,
			.justify-content-center,
			.align-items-center,
			.overflow-hidden !optional;
	}
	.card-img-top a {
		@extend .h-100 !optional;
		a {
			cursor: pointer;
		}
	}
	.card-img-top,
	.card-img-overlay {
		height: 285px;
	}
	.card-body {
		@extend .d-flex, .flex-column, .overflow-hidden !optional;
		.card-text {
			@extend .flex-grow-1 !optional;
		}
	}
	.card-footer {
		background-color: #fff;
	}
}

.card-img-top img,
.join-action img,
.search-action img,
.offer-action img {
	@extend .h-100, .w-100 !optional;
	object-fit: cover;
}

.slick-track {
	height: 400px;
}

.dropdown-item:active,
option:hover {
	background-color: #ed6745 !important;
}

input[type='number'] {
	-moz-appearance: textfield;
}
